import ApiClient from "@apis/apiClient";
import ServerApi from "@apis/server";
import ServiceApi from "@apis/service";
import { ApiContext } from "./ApiContext";
import TemplateApi from "@apis/template";
import AdminApi from "@apis/admin";
import ShopApi from "@apis/shop";
import FuneralApi from "@apis/funeral";

const client = new ApiClient();

const serverApi = new ServerApi(client);
const serviceApi = new ServiceApi(client);
const templateApi = new TemplateApi(client);
const adminApi = new AdminApi(client);
const shopApi = new ShopApi(client);
const funeralApi = new FuneralApi(client);

export function ApiProvider({ children }) {
  return (
    <ApiContext.Provider
      value={{
        serverApi,
        serviceApi,
        templateApi,
        adminApi,
        shopApi,
        funeralApi,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}
