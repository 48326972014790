import axios from "axios";

export default class ApiClient {
  constructor() {
    const token = localStorage.getItem("access_token");
    this.httpClient = axios.create({
      headers: { Authorization: token },
      baseURL: `${process.env.REACT_APP_API_URL}/api/`,
      withCredentials: true,
    });
  }

  async getObituary(id) {
    return this.httpClient.get(`obituary/${id}`);
  }

  // new - admin 관련 api

  async getDashboard() {
    return this.httpClient.get(`admin/dashboard`);
  }

  async getSalesChartData(params) {
    return this.httpClient.post(`admin/sales-chart`, params);
  }

  async getAuth() {
    return this.httpClient.get(`admin/auth`);
  }
  async getAdmins(params) {
    return this.httpClient.post(`admin/admin-list`, params);
  }

  async saveOrUpdateAdminUser(params) {
    return this.httpClient.post(`admin/admin-save`, params);
  }

  async getAdminGroups() {
    return this.httpClient.get(`admin/group-list`);
  }

  async saveOrUpdateAdminGroup(params) {
    return this.httpClient.post(`admin/group-save`, params);
  }

  async login(params) {
    return this.httpClient.post(`admin/login`, params);
  }

  async getAdminEvents(params) {
    const query = new URLSearchParams(params.searchFilter).toString();
    return this.httpClient.get(`admin/events?${query}`);
  }

  async updateEventState(params) {
    return this.httpClient.post(`admin/event-state/update`, params);
  }

  async saveOrUpdateEvent(params) {
    return this.httpClient.post(`admin/event-save`, params);
  }

  async getAdminEvent(event_no) {
    return this.httpClient.get(`admin/events/${event_no}`);
  }

  async updateEventSubtitle(params) {
    // 관리자가 자막 수정
    return this.httpClient.post(`admin/event-subtitle/update`, params);
  }

  async deleteEventImages(params) {
    // 관리자가 자막 수정
    return this.httpClient.post(`admin/event-images/delete`, params);
  }

  // 서비스 관련 API
  async checkEvent(event_no) {
    // 본 서버 DB 내 행사 존재여부만 파악
    return this.httpClient.get(`service/check-event?event_no=${event_no}`);
  }

  async registerEvent(params) {
    // Event user 등록
    return this.httpClient.post(`service/event/register`, params);
  }

  async selectedEventUser(uid, userId) {
    return this.httpClient.post(`service/event/selected-user`, { uid, userId });
  }

  async getEvent(uid) {
    // 행사 정보 가져오기
    return this.httpClient.get(`service/events/${uid}`);
  }

  async updateEventOrder(params) {
    // 행사 정보와 주문 같이 가져오기
    return this.httpClient.post(`service/event/update-order`, params);
  }

  async updateEvent(params) {
    // 행사 정보 변경
    return this.httpClient.post(`service/event/update`, { event: params });
  }

  async saveEventImageURL(params) {
    // 업로드 된 사진 url 저장
    return this.httpClient.post(`service/event/save-image-url`, params);
  }

  async deleteEventImageURL(params) {
    // 업로드 된 사진 삭제
    return this.httpClient.post(`service/event/delete-image-url`, params);
  }
  async deleteEventRepeatImage(params) {
    // 반복사용된 사진 삭제
    return this.httpClient.post(`service/event/delete-repeat-image`, params);
  }

  async getEventSubtitle(uid) {
    // 자막 정보 불러오기
    return this.httpClient.get(`service/events/subtitles/${uid}`);
  }

  async saveEventSubtitle(params) {
    // 자막 수정 ( 고객 )
    return this.httpClient.post(`service/event/save-subtitles`, params);
  }

  // 작업(Queue) 관련 API
  async getQueues(params) {
    const query = new URLSearchParams(params.searchFilter).toString();
    return this.httpClient.get(`queues?${query}`);
  }

  async getQueue(queueId) {
    return this.httpClient.get(`queue/${queueId}`);
  }

  async startQueue(params) {
    return this.httpClient.post(`queue/start`, params);
  }

  async cancelQueue(params) {
    return this.httpClient.post(`queue/cancel`, params);
  }

  async saveOrUpdateQueue(params) {
    return this.httpClient.post(`queue/save`, params);
  }

  async startShortQueue(params) {
    return this.httpClient.post(`queue/short-start`, params);
  }

  async retryQueue(params) {
    return this.httpClient.post(`queue/retry`, params);
  }

  async setFreeQueue(obituary) {
    return this.httpClient.post(`queue/free`, { obituary });
  }

  async updateQueue(queue) {
    return this.httpClient.put(`queue/`, { queue });
  }

  // 템플릿 관련 API
  async getTemplate(id, target) {
    return this.httpClient.get(`template/${id}?target=${target}`);
  }

  async getTemplateAssets({ templateId, storage }) {
    return this.httpClient.get(
      `template/assets?templateId=${templateId}&storage=${storage}`
    );
  }

  async getTemplateAssetDetail(templateId) {
    return this.httpClient.get(
      `template/asset-detail?templateId=${templateId}`
    );
  }

  async updateTemplateAssets(params) {
    return this.httpClient.post("template/update-assets", params);
  }

  async getFreeTemplate() {
    return this.httpClient.get(`template/free`);
  }

  async getTemplates(target) {
    return this.httpClient.get(`templates?target=${target}`);
  }

  // 템플릿 카테고리 불러오기
  async getTemplateCategorys() {
    return this.httpClient.get(`templates/category-list`);
  }

  //템플릿 카테고리 생성 및 수정
  async saveOrUpdateTemplateCategory(params) {
    return this.httpClient.post(`templates/category-save`, params);
  }

  //템플릿 생성 및 수정
  async saveOrUpdateTemplate(params) {
    return this.httpClient.post(`template/save`, params);
  }

  //템플릿 자막 & 이미지 정보 수정
  async updateTempSubAndImage(params) {
    return this.httpClient.post(`template/sub-and-image/update`, params);
  }

  /* 주문 관련 API */
  // 주문 목록 불러오기
  async getOrders(params) {
    const query = new URLSearchParams(params.searchFilter).toString();
    return this.httpClient.get(`orders?${query}`);
  }

  async getDelivery(params) {
    const query = new URLSearchParams(params.searchFilter).toString();
    return this.httpClient.get(`orders/delivery?${query}`);
  }

  async updateDelivery(params) {
    return this.httpClient.post(`orders/deli-save`, params);
  }
  // 관리비 정산 목록 불러오기
  async getOrderCals(params) {
    const query = new URLSearchParams(params.searchFilter).toString();
    return this.httpClient.get(`orders/cal-list?${query}`);
  }
  // 주문 정보 불러오기
  async getOrder(imp_uid) {
    return this.httpClient.get(`order?imp_uid=${imp_uid}`);
  }

  async getReOrder(merchant_uid) {
    return this.httpClient.get(`order?merchant_uid=${merchant_uid}`);
  }

  // 주문 등록
  async saveOrUpdateOrder(params) {
    return this.httpClient.post(`order/save`, params);
  }

  async cancelOrder(params) {
    return this.httpClient.post(`order/cancel`, params);
  }

  /* 상품 관련 API */
  async getShop(shop_no) {
    return this.httpClient.get(`shop?shop_no=${shop_no}`);
  }

  async getFuneralShopTemplates(params) {
    const query = new URLSearchParams(params).toString();
    return this.httpClient.get(`shop/funeral-templates?${query}`);
  }

  async getShopTemplate(params) {
    const query = new URLSearchParams(params).toString();
    return this.httpClient.get(`shop/template?${query}`);
  }

  async getShops(params) {
    const query = new URLSearchParams(params?.searchFilter).toString();
    return this.httpClient.get(`shop/shop-list?${query}`);
  }

  async saveOrUpdateShop(params) {
    return this.httpClient.post(`shop/shop-save`, params);
  }

  async getShopCategorys() {
    return this.httpClient.get(`shop/category-list`);
  }

  async saveOrUpdateShopCategory(params) {
    return this.httpClient.post(`shop/category-save`, params);
  }
  async saveOrUpdateShopDetailImage(params) {
    return this.httpClient.post(`shop/detail-image-save`, params);
  }

  /**장례식장 api */
  async getAdminFunerals(params) {
    const query = new URLSearchParams(params.searchFilter).toString();
    return this.httpClient.get(`admin/funerals?${query}`);
  }

  async getFunerals() {
    return this.httpClient.get(`funeral/list`);
  }

  async saveOrUpdateFuneral(params) {
    return this.httpClient.post(`admin/funerals/save`, params);
  }

  // 장례식장 삭제
  async deleteFuneral(funeral_no) {
    return this.httpClient.delete(`admin/funerals/${funeral_no}`);
  }

  // 장례식장 상품 가져오기
  async getFuneralShop(funeral_no) {
    return this.httpClient.get(`admin/funeral-shop?funeral_no=${funeral_no}`);
  }

  async saveOrUpdateFuneralShop(params) {
    return this.httpClient.post(`admin/funeral-shop/save`, params);
  }

  async saveOrUpdateFuneralPayback(params) {
    return this.httpClient.post(`admin/funerals/payback-save`, params);
  }

  /**정산관련 api */
  async getFuneralCals(params) {
    const query = new URLSearchParams(params.searchFilter).toString();
    return this.httpClient.get(`admin/funeral-cals?${query}`);
  }

  async getFuneralCal(params) {
    const query = new URLSearchParams(params.searchFilter).toString();
    return this.httpClient.get(
      `admin/funeral-cal?${query}&FUNERAL_NO=${params.FUNERAL_NO}`
    );
  }

  // 제휴관련 api
  async getContacts(params) {
    const query = new URLSearchParams(params.searchFilter).toString();
    return this.httpClient.get(`admin/contacts?${query}`);
  }

  async saveOrUpdateContact(params) {
    return this.httpClient.post(`admin/contacts/save`, params);
  }

  //어드민 페이지 - 후기 가져오기
  async getAdminFeedbacks(params) {
    const query = new URLSearchParams(params.searchFilter).toString();
    return this.httpClient.get(`admin/feedbacks?${query}`);
  }
  // 후기 확인
  async confirmFeedback(id) {
    return this.httpClient.post(`admin/feedbacks/confirm`, { id });
  }

  // 후기 삭제
  async deleteFeedback(id) {
    return this.httpClient.delete(`admin/feedbacks/${id}`);
  }

  // 서비스 후기 가져오기
  async getFeedbacks(params) {
    const query = new URLSearchParams(params).toString();
    return this.httpClient.get(`service/feedbacks?${query}`);
  }

  // 후기등록
  async saveFeedback(params) {
    return this.httpClient.post(`service/feedback/save`, params);
  }
}
