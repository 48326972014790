export default class ServerApi {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  // 템플릿 관련

  async getFreeTemplate() {
    return this.apiClient
      .getFreeTemplate()
      .then((res) => res.data.resultFreeTemplate);
  }

  // 부고 관련
  async getObituary(obituaryId) {
    return this.apiClient
      .getObituary(obituaryId)
      .then((res) => res.data.resultObituary);
  }

  // 작업 관련
  async setFreeQueue(obituary) {
    return this.apiClient
      .setFreeQueue(obituary)
      .then((res) => res.data.resultFreeQueue);
  }

  async updateQueue(queue) {
    return this.apiClient
      .updateQueue(queue)
      .then((res) => res.data.resultQueue);
  }

  // 여기부터 관리자
  async getCompanys(company) {
    return this.apiClient
      .getCompanys(company)
      .then((res) => res.data.resultCompanyList);
  }

  async setCompany(params) {
    return this.apiClient
      .setCompany(params)
      .then((res) => res.data.resultCompany);
  }

  async companyIdVaildCheck(userId) {
    return this.apiClient
      .getCompany(userId)
      .then((res) => res.data.resultCompany);
  }

  // =========================================== //
  async getTemplate(id, target) {
    return this.apiClient
      .getTemplate(id, target)
      .then((res) => {
        const { data } = res;

        return data.result.template;
      })
      .catch((err) => console.error(err));
  }

  async getTemplates() {
    return this.apiClient
      .getTemplates()
      .then((res) => {
        const { data } = res;

        return data.result.templates;
      })
      .catch((err) => console.error(err));
  }

  async updateTemplateAssets(updateAssets) {
    return this.apiClient
      .updateTemplateAssets(updateAssets)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return true;
      })
      .catch((err) => console.error(err));
  }
}
