import React from "react";
import styles from "./Hamburger.module.css";

// props 타입을 정의합니다.
interface HamburgerProps {
  isOpen: boolean;
  onClick: () => void; // onClick 이벤트 핸들러 타입
}

const Hamburger: React.FC<HamburgerProps> = ({ isOpen, onClick }) => {
  return (
    <button
      className={`${styles.hamburger} ${isOpen ? styles.open : ""}`}
      onClick={onClick}
    >
      {/* isOpen 상태에 따라 아이콘 스타일 변경 */}
      <div
        className={`${styles.hamburgerLine} ${isOpen ? styles.line1 : ""}`}
      />
      <div
        className={`${styles.hamburgerLine} ${isOpen ? styles.line2 : ""}`}
      />
      <div
        className={`${styles.hamburgerLine} ${isOpen ? styles.line3 : ""}`}
      />
    </button>
  );
};

export default Hamburger;
