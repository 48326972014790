import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Navigate, Link } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";
import { useApi } from "@context/ApiContext";

import loadingText from "@assets/images/service/loading_text.webp";

import Thumbnail from "@assets/images/service/serviceThumbnail2.webp";
import star from "@assets/images/service/star.webp";
import petal from "@assets/images/service/Petal.webp";

import ServiceInfoInputModal from "@components/Service/ServiceInfoInputModal";
import { AgreeState } from "@interfaces/service";
import KakaoInquiryModal from "@components/Ui/KakaoInquiryModal";
import MainFooter from "@components/Home/MainFooter";
import Feedbacks from "@components/Service/Feedbacks";
import VideoSample from "@components/Service/VideoSample";
import ServiceDetail from "@components/Service/ServiceDetail";

import { TemplateObj } from "@interfaces/admin";
import BottomOrderSection from "@components/Service/BottomOrderSection";
import KakaoInquiryButton from "@components/Service/KakaoInquiryButton";

interface NewEventProps {}

const NewEvent: React.FC<NewEventProps> = ({}) => {
  const navigator = useNavigate();
  const location = useLocation();
  const { serviceApi, templateApi, shopApi } = useApi();

  const [loading, setLoading] = useState(true);

  const [infoModalFlag, setInfoModalFlag] = useState(false);
  const [kakaoInquiryModalFlag, setKakaoInquiryModalFlag] = useState(false);
  const [agreeState, setAgreeState] = useState<AgreeState>({
    serviceAgree: false,
    privacyAgree: false,
    marketingAgree: false,
  });

  const [selectedShop, setSelectedShop] = useState<any>(null);

  const uid = new URLSearchParams(location.search).get("event_no");

  // 1. 백엔드에 쿼리에 있는 행사번호를 보낸다.
  // 2. 고객 유무를 파악한다.
  const { isLoading, isError, data } = useQuery(
    ["checkEvent", uid],
    () => serviceApi.checkEvent(uid),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const { isLoading: isTemplateLoading, data: templates } = useQuery<
    TemplateObj[],
    Error
  >(["templates"], () => templateApi.getTemplates("shop"), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const { data: categorys } = useQuery(
    ["getTemplateCategorys"],
    () => templateApi.getTemplateCategorys(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );
  const { data: funeralTemplates } = useQuery(
    ["getFuneralShopTemplates"],
    () => shopApi.getFuneralShopTemplates({ FUNERAL_NO: data?.FUNERAL_NO }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
      enabled: !!data?.FUNERAL_NO, // FUNERAL_NO이 있을때만
    }
  );

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    setTimeout(() => {
      setLoading(false);
    }, 1800);
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [loading]);

  // 3. 새로운 고객이면 개인정보 동의확인을 받는다.
  // 4. 동의하기 버튼 클릭 시 백엔드에 고객등록을 요청한다
  // 4-1 고객등록이 완료되면 /info-check 페이지로 이동시킨다.

  // if (isLoading) return <Loading />;
  // if (isError) return <Error />;

  // 개인정보 동의 및 행사 등록 로직
  const handlePurchase = async (template: any) => {
    if (data?.ShopOrders?.length > 0) {
      navigator(`/service/info-check?uid=${data.uid}`);
    }

    if (!uid && !data?.FUNERAL_NO) {
      setKakaoInquiryModalFlag(true);
      return;
    }

    if (!data) {
      setKakaoInquiryModalFlag(true);
      return;
    }

    const endDate = new Date(data?.end_dt);

    // Get the current time
    const currentTime = new Date();

    if (endDate < currentTime) {
      setKakaoInquiryModalFlag(true);
      return;
    }

    //SeletedTemplateId로 funeralShopTemplates 조회

    const funeralShopTemplate = funeralTemplates?.find(
      (t: any) => t?.Shop?.TemplateId === template?.id
    );

    if (!data?.ShopOrders || data?.ShopOrders?.length === 0) {
      const queryParams = new URLSearchParams(location.search);
      // cate랑 event_no 삭제
      queryParams.delete("cate");
      queryParams.delete("event_no");
      queryParams.set("uid", data.uid);
      queryParams.set("FUNERAL_NO", data.FUNERAL_NO);
      queryParams.set("shop_no", funeralShopTemplate?.Shop?.shop_no || "");
      queryParams.set(
        "templateId",
        funeralShopTemplate?.Shop?.TemplateId || template?.id
      );
      navigator(`/shops/order?${queryParams.toString()}`);

      return;
    }

    // setInfoModalFlag(true);
  };

  const handleMakeVideo = async () => {
    console.log(data?.ShopOrders);

    if (data?.ShopOrders[0]?.receive_addr.trim() === "") {
      navigator(`/service/delivery-info?uid=${data.uid}`);
      return;
    }
    navigator(`/service/info-check?uid=${data.uid}`);
  };

  // 상품가격이 0원인 경우 바로제작
  const handleDirectMakeVideo = async (template: any) => {
    setInfoModalFlag(true);

    const funeralShopTemplate = funeralTemplates?.find(
      (t: any) => t.Shop.TemplateId === template.id
    );

    setSelectedShop(funeralShopTemplate.Shop);
  };

  const registEventMember = async (eventUser: any) => {
    try {
      if (!eventUser?.name?.trim()) {
        alert("성함을 입력하여 주십시요.");
        return;
      }
      if (!eventUser?.phone_number?.trim()) {
        alert("휴대폰 번호를 입력하여 주십시요.");
        return;
      }

      if (!agreeState.serviceAgree) {
        alert("서비스 이용약관 동의는 필수 입니다.");
        return;
      }

      if (!agreeState.privacyAgree) {
        alert("개인정보 수집 및 이용 동의는 필수 입니다.");
        return;
      }

      const params = {
        uid,
        eventUser: {
          ...eventUser,
          service_agree: agreeState.serviceAgree,
          privacy_agree: agreeState.privacyAgree,
          marketing_agree: agreeState.marketingAgree,
        },
        state: "prepared",
        templateId: selectedShop?.TemplateId,
        shop: {
          shop_no: selectedShop?.shop_no,
          shop_price: selectedShop?.shop_price,
          shop_name: selectedShop?.shop_name,
        },
      };
      serviceApi.registerEvent(params).then((res: any) => {
        if (!res) return;
        navigator(`/service/delivery-info?uid=${res.event.uid}`);
      });
    } catch (error) {
      console.error(error);
    }
  };

  if (
    data?.template_state === "premium" &&
    ["completed", "making", "done", "confirmed"].includes(data?.state)
  ) {
    return <Navigate to={`/service/complete?uid=${data.uid}`} replace />;
  }

  return (
    <div id="newEvent">
      <ServiceInfoInputModal
        event={data}
        isOpen={infoModalFlag}
        onClose={() => setInfoModalFlag(false)}
        registEventMember={registEventMember}
        agreeState={agreeState}
        setAgreeState={setAgreeState}
      />
      <KakaoInquiryModal
        title="발인이 완료된 장례식입니다"
        mainText={`발인이 완료되어\n서비스 진행이 어렵습니다.\n\n문의 또는 도움이 필요하신 경우\n아래 문의처를 통해 연락주시면 최대한\n신속하게 도와드리겠습니다.\n\n고객지원\n1577-3264`}
        isOpen={kakaoInquiryModalFlag}
        onClose={() => setKakaoInquiryModalFlag(false)}
      />

      {loading && (
        <div id="splash-loading">
          <img src={loadingText} alt="loading-text-img" />
        </div>
      )}
      <div className="newEvent-mainImage">
        <img className="newEvent-petal" src={petal} alt="" />
        <div className="newEvent-title">
          <div className="newEvent-title-en">Memorial Movie</div>
          <div>소중한 순간,</div>
          <div>아름다운 기억을 남깁니다.</div>
          <p>사랑하는 마음을 기억하는 방법</p>
        </div>
        <div className="newEvent-PersonalInfo">
          <img className="thumbnail" src={Thumbnail} alt="" />
        </div>
      </div>

      <VideoSample
        templates={templates}
        categorys={categorys}
        isLoading={isTemplateLoading}
      />

      <article className="newEvent-article">
        <div>
          <h3>사진이 부족하고...</h3>
          <h3>시간이 모자라서...</h3>
        </div>
        <div>
          <img src={star} alt="" />
        </div>
        <div>
          <p>
            <span>사진 1장만 있어도</span> 만들 수 있습니다.
          </p>
          <p>
            <span>장례식 이후에도</span> 제작 가능합니다.
          </p>
          <h3>기억이 담긴 사진만 올려주세요.</h3>
        </div>
      </article>
      <div className="newEvent-feedbacks">
        <Feedbacks />
      </div>
      <ServiceDetail template={templates?.[0]} />
      <KakaoInquiryButton />
      <MainFooter />

      {data?.ShopOrders?.length > 0 || data?.EventUsers?.length > 0 ? (
        <section className="bottom-order-section">
          <button className="main-button" onClick={handleMakeVideo}>
            <h6 className="head-title medium">추모영상 제작하기</h6>
          </button>
        </section>
      ) : (
        <>
          {funeralTemplates && !loading && (
            <BottomOrderSection
              templates={templates}
              title="추모영상 제작"
              price={funeralTemplates[0]?.shop_price}
              handlePurchase={handlePurchase}
              handleDirectMakeVideo={handleDirectMakeVideo}
            />
          )}

          {!funeralTemplates && !loading && (
            <section className="bottom-order-section">
              <KakaoInquiryButton style={{ width: "100%", margin: 0 }} />
            </section>
          )}
        </>
      )}
    </div>
  );
};

export default NewEvent;
